import React from 'react';
import $ from "jquery";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import XmlArea from "./XmlArea";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.waitForResult = this.waitForResult.bind(this);
        this.handleAjaxFailure = this.handleAjaxFailure.bind(this);
        this.modifyRequestButtonClicked = this.modifyRequestButtonClicked.bind(this);
        this.sendRequestButtonClicked = this.sendRequestButtonClicked.bind(this);
        this.cancelButtonClicked = this.cancelButtonClicked.bind(this);
        this.state = {
            requestText: "",
            responseText: "",
            errorText: "",
            activeTab: "request",
            modifyRequestButtonDisabled: true,
            sendRequestButtonDisabled: false,
            cancelButtonDisabled: true,
            requestDisabled: false
        };
    }

    sendRequestButtonClicked() {
        this.setState({
            errorText: "",
            sendRequestButtonDisabled: true,
            requestDisabled: true
        })
        this.ajax(
            "calculate",
            {request: this.state.requestText},
            this.waitForResult,
            this.handleAjaxFailure
        );
    }

    cancelButtonClicked() {
        let id = this.id;
        this.ajax(
            "cancel",
            {id: id},
            () => {
            },
            () => {
            }
        );
    }

    modifyRequestButtonClicked() {
        this.setState({
            responseText: "",
            errorText: "",
            activeTab: "request",
            buttonText: "XML-Anfrage senden",
            requestDisabled: false,
            modifyRequestButtonDisabled: true,
            sendRequestButtonDisabled: false
        });
    }

    waitForResult(responseWithId) {
        this.id = responseWithId.id;
        this.setState({cancelButtonDisabled: false});
        this.ajax(
            "getResult",
            {id: responseWithId.id},
            (response) => {
                switch (response.kind) {
                    case "progress":
                        this.waitForResult(responseWithId);
                        break;
                    case "result":
                        this.handleReceivedResult(response.result);
                        break;
                    default:
                        this.handleAjaxFailure("unknown result kind" + response.kind)
                }

            },
            this.handleAjaxFailure
        )
    }


    handleReceivedResult(result) {
        if (result.saxError) {
            this.setState({
                responseText: "",
                errorText: result.saxError,
                activeTab: "error",
                sendRequestButtonDisabled: false,
                cancelButtonDisabled: true,
                requestDisabled: false
            });
        } else if (result.error) {
            this.setState({
                responseText: "",
                errorText: result.error,
                activeTab: "error",
                sendRequestButtonDisabled: false,
                cancelButtonDisabled: true,
                requestDisabled: false
            });
        } else {
            this.setState({
                responseText: result.response,
                activeTab: "response",
                cancelButtonDisabled: true,
                modifyRequestButtonDisabled: false
            });
        }
    }

    handleAjaxFailure(errorMessage) {
        this.setState({
            responseText: "",
            errorText: errorMessage,
            activeTab: "error",
            sendRequestButtonDisabled: false,
            cancelButtonDisabled: true,
            requestDisabled: false
        });
    }

    ajax(command, parameters, callbackDone, callbackFail) {
        let request = $.ajax({
            url: "ajax",
            type: "post",
            data: {command: command, parameters: JSON.stringify(parameters)},
            dataType: "text",
            timeout: 10000
        });
        request.done(function (text) {
            let response = JSON.parse(text);
            console.log(response);
            callbackDone(response, parameters);
        });
        request.fail(function (jqXHR, textStatus, errorThrown) {
            let errorMessage = "Ajax request failed with textStatus '" + textStatus + "' and errorThrown '" + errorThrown + "'";
            console.log(errorMessage);
            callbackFail(errorMessage);
        });
    }

    render() {
        return (
            <div className="App">
                <h1>AutoTestPlan Debugger</h1>
                <div style={{flexGrow: 1}}>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={this.state.activeTab}
                        onSelect={(k) => this.setState({activeTab: k})}
                    >
                        <Tab eventKey="request" title="XML-Anfrage" style={{height: "100%"}}>
                            <XmlArea
                                value={this.state.requestText}
                                placeholder="hier XML-Request einfügen..."
                                onChange={(event) => {
                                    this.setState({requestText: event.target.value})
                                }}
                                disabled={this.state.requestDisabled}
                            />
                        </Tab>
                        <Tab eventKey="response" title="XML-Antwort" style={{height: "100%"}}>
                            <XmlArea
                                disabled
                                value={this.state.responseText}
                            />
                        </Tab>
                        <Tab eventKey="error" title="Fehlermeldung" style={{height: "100%"}}>
                            <XmlArea
                                disabled
                                value={this.state.errorText}
                            />
                        </Tab>
                    </Tabs>
                </div>
                <div style={{fontSize: "10px", textAlign: "right", color: "#808080", marginTop: "-20px"}}>
                    nur für den internen Gebrauch innerhalb des STZ OIT
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                    <Button
                        style={{flexGrow: 0, marginRight: "20px"}}
                        onClick={this.modifyRequestButtonClicked}
                        disabled={this.state.modifyRequestButtonDisabled}
                    >
                        XML-Anfrage ändern
                    </Button>
                    <Button
                        style={{flexGrow: 0, marginRight: "20px"}}
                        onClick={this.cancelButtonClicked}
                        disabled={this.state.cancelButtonDisabled}
                    >
                        Berechnung abbrechen
                    </Button>
                    <Button
                        style={{flexGrow: 0}}
                        onClick={this.sendRequestButtonClicked}
                        disabled={this.state.sendRequestButtonDisabled}
                    >
                        XML-Anfrage abschicken
                    </Button>
                </div>
            </div>
        );
    }
}

export default App;
