import React from "react";
import Form from 'react-bootstrap/Form'

class XmlArea extends React.Component {
    render() {
        return (
            <Form>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control
                        as="textarea"
                        placeholder={this.props.placeholder}
                        style={{
                            fontFamily: "Menlo, monospace",
                            fontSize: "10pt",
                            resize: "none",
                            scroll: "both",
                            height: "calc(100vh - 220px)",
                            minHeight: "100px",
                            whiteSpace: "pre",
                            backgroundColor: this.props.disabled ? "#eeeeee" : "white",
                            color: "black"
                        }}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        disabled={this.props.disabled}
                    />
                </Form.Group>
            </Form>
        );
    }
}

export default XmlArea